// nuxt-ui/middleware/notFoundQueensAmericas.ts
import { useWebContext } from "~/nuxt-ui/Shared/composables/webContext";

export default defineNuxtRouteMiddleware((from, to) => {
  const { isQueens } = useWebContext();

  if (isQueens && to.params.region === "americas") {
    throw createError({ statusCode: 404 });
  }
});
